import React, { Component } from 'react'

class Resume extends Component {
	render() {
		if (this.props.data) {
			var education = this.props.data.education.map(function (education) {
				return (
					<div key={education.degree}>
						<h3>{education.school}</h3>
						<p className='info'>
							{education.degree} <span>&bull;</span>
							<em className='date'>{education.graduated}</em>
						</p>
						{/* <p>{education.description}</p> */}
					</div>
				)
			})

			var work = this.props.data.work.map(function (work) {
				let duties = []
				if (work.duties) {
					duties.push(
						<span key={Math.random()} style={{ fontWeight: 'bold', color: 'black' }}>
							Duties
						</span>
					)
					let duties_items = work.duties.map((item, index) => {
						let i = item.key + '_' + index
						return <li key={i}>{item.txt}</li>
					})
					duties.push(
						<ul key={Math.random()} style={{ paddingLeft: '10px', listStyle: 'inside' }}>
							{duties_items}
						</ul>
					)
				}

				return (
					<div key={work.company} style={{ marginBottom: '50px' }}>
						<h3>{work.company}</h3>
						<p className='info'>
							{work.title}
							<span>&bull;</span> <em className='date'>{work.years}</em>
						</p>
						<p>{work.description}</p>
						{duties}
					</div>
				)
			})

			var skills = this.props.data.skills.map(function (skills) {
				let className = 'bar-expand ' + skills.name.toLowerCase()
				return (
					<li key={skills.name}>
						<span style={{ width: skills.level }} className={className}></span>
						<em>{skills.name}</em>
					</li>
				)
			})
		}

		return (
			<>
			<section id='skills'>
					<div className='row skill'>
						<div className='three columns header-col'>
							<h1>
								<span>Skills</span>
							</h1>
						</div>

						<div className='nine columns main-col'>
							<div className='bars'>
								<ul className='skills'>{skills}</ul>
							</div>
						</div>
					</div>
				</section>
				<section id='education'>
					<div className='row education'>
						<div className='three columns header-col'>
							<h1>
								<span>Education</span>
							</h1>
						</div>

						<div className='nine columns main-col'>
							<div className='row item'>
								<div className='twelve columns'>{education}</div>
							</div>
						</div>
					</div>
				</section>
				<section id='resume'>
					<div className='row work'>
						<div className='three columns header-col'>
							<h1>
								<span>Work</span>
							</h1>
						</div>

						<div className='nine columns main-col'>{work}</div>
					</div>
				</section>
			</>
		)
	}
}

export default Resume
