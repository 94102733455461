import React, { Component } from 'react'
import $ from 'jquery'
import './App.css'
import Header from './Components/Header'
import Footer from './Components/Footer'
import About from './Components/About'
import Resume from './Components/Resume'
import Contact from './Components/Contact'
// import Testimonials from './Components/Testimonials';
import Portfolio from './Components/Portfolio'

class App extends Component {
	state = {
		resumeData: {},
	}

	getResumeData = () => {
		$.ajax({
			url: '/resumeData.json',
			dataType: 'json',
			cache: false,
			success: function (data) {
				this.setState({ resumeData: data })
			}.bind(this),
			error: function (_xhr, _status, err) {
				console.log(err)
				alert(err)
			},
		})
	}

	componentDidMount = () => {
		this.getResumeData()
	}

	render() {
		return (
			<div className='App'>
				<Header data={this.state.resumeData.main} />
				<About data={this.state.resumeData.main} />
				<Portfolio data={this.state.resumeData.portfolio} />
				<Resume data={this.state.resumeData.resume} />
				{/*<Testimonials data={this.state.resumeData.testimonials}/>*/}
				<Contact data={this.state.resumeData.main} />
				<Footer data={this.state.resumeData.main} />
			</div>
		)
	}
}

export default App
