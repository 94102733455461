import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'

const uriEncode = (data) => {
	console.log('Encoding Contact Form data ->', data)
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&')
}

class Contact extends Component {
	render() {
		// if (this.props.data) {
		// 	var email = this.props.data.email
		// }

		return (
			<section id='contact' style={{ textAlign: 'center' }}>
				<div className='row'>
					<h1>Get In Touch</h1>
					<div className='twelve columns'>
						<Formik
							initialValues={{
								name: '',
								email: '',
								subject: '',
								message: '',
							}}
							onSubmit={(values, actions) => {
								fetch('/', {
									method: 'POST',
									headers: {
										'Content-Type': 'application/x-www-form-urlencoded',
									},
									body: uriEncode({
										'form-name': 'contact',
										...values,
									}),
									// body: '',
								})
									.then((response) => {
										console.log('Submitting form ->', response)
										if (!response.ok) {
											throw new Error(response.status)
										} else if (response.ok) {
											actions.resetForm()
										}
										return response
									})
									.catch((error) => {
										console.error(error)
									})
									.finally(() => actions.setSubmitting(false))
							}}
							validate={(values) => {
								const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
								const errors = {}
								if (!values.name || values.name.length < 4) {
									errors.name = 'Name Required'
								}
								if (!values.email || !emailRegex.test(values.email)) {
									errors.email = 'Valid Email Required'
								}
								if (!values.subject || values.subject.length < 4) {
									errors.subject = 'Subject Required'
								}
								if (!values.message || values.message.length < 4) {
									errors.message = 'Message Required'
								}
								return errors
							}}
						>
							{() => (
								<Form name='contact' data-netlify={true} data-netlify-honeypot='bot-field'>
									<input type='hidden' name='form-name' value='contact' />
									<div>
										<label htmlFor='name'>Your Name: </label>
										<Field type='text' name='name' />
										<ErrorMessage
											name='name'
											render={(msg) => <div className='formik-error-message'>{msg}</div>}
										/>
									</div>

									<div>
										<label htmlFor='email'>Your Email: </label>
										<Field type='email' name='email' />
										<ErrorMessage
											name='email'
											render={(msg) => <div className='formik-error-message'>{msg}</div>}
										/>
									</div>

									<div>
										<label htmlFor='subject'>Subject: </label>
										<Field type='text' name='subject' />
										<ErrorMessage
											name='subject'
											render={(msg) => <div className='formik-error-message'>{msg}</div>}
										/>
									</div>

									<div>
										<label htmlFor='message'>Message: </label>
										<Field
											name='message'
											component='textarea'
											style={{ resize: 'none' }}
											maxLength='900'
										/>
										<ErrorMessage
											name='message'
											render={(msg) => <div className='formik-error-message'>{msg}</div>}
										/>
									</div>

									<div>
										<button type='submit'>Send</button>
									</div>
								</Form>
							)}
						</Formik>
						{/* Other messages can be sent to{' '}
						<a href='mailto:191.vinicius@gmail.com?subject=New contact from portfolio website'>{email}</a> */}
					</div>
				</div>
			</section>
		)
	}
}

export default Contact
