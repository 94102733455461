import React, { Component } from 'react'

class Portfolio extends Component {
	render() {
		if (this.props.data) {
			this.projects = this.props.data.projects.map(function (projects) {
				let projectImage = 'images/portfolio/' + projects.image
				return (
					<div key={projects.title} className='columns portfolio-item'>
						<div className='item-wrap'>
							<a href={projects.url} title={projects.title} target='_blank' rel='noopener noreferrer'>
								<img alt={projects.title} src={projectImage} />
								<div className='overlay'>
									<div className='portfolio-item-meta'></div>
								</div>
								<div className='link-icon'>
									<i className='fa fa-link'></i>
								</div>
							</a>
						</div>
						<div className='portfolio_desc'>
							<h5>{projects.title}</h5>
							<p>{projects.category}</p>
							{projects.git ? (
								<a title={'View code of ' + projects.title} href={projects.git} target='_blank' rel='noopener noreferrer'>
									<button className='btn-small'>
										<i className='fa fa-github'></i>View code
									</button>
								</a>
							) : null}
						</div>
					</div>
				)
			})
		}

		return (
			<section id='portfolio'>
				<div className='row'>
					<div className='twelve columns collapsed'>
						<h1>Check Out Some of My Work</h1>

						<div id='portfolio-wrapper' className='bgrid-thirds s-bgrid-thirds cf'>
							{this.projects}
						</div>
					</div>
				</div>
			</section>
		)
	}
}

export default Portfolio
